<template>
  <v-dialog
    v-model="visible"
    class="time-modal"
    scrollable
    max-width="750"
  >
    <v-card
      v-if="action === 'delete'"
      class="pa-6"
    >
      <v-card-title primary-title>
        <div class="headline darkestblue--text">
          Are you sure?
        </div>
      </v-card-title>

      <v-card-text>
        <div class="coolgray--text">
          <v-row>
            <v-col class="subtitle-2">
              ID
            </v-col>
            <v-col class="body-1">
              {{ entry.timeEntryId }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="subtitle-2">
              Person
            </v-col>
            <v-col class="body-1">
              {{ entry.personName }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="subtitle-2">
              Client
            </v-col>
            <v-col class="body-1">
              {{ entry.clientName }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="subtitle-2">
              Project
            </v-col>
            <v-col class="body-1">
              {{ entry.projectName }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="subtitle-2">
              Time Type
            </v-col>
            <v-col class="body-1">
              {{ entry.timeClassificationName }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="subtitle-2">
              Duration
            </v-col>
            <v-col class="body-1">
              {{ durationFormatted }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="subtitle-2">
              Notes
            </v-col>
            <v-col class="body-1">
              {{ entry.description }}
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn
          text
          color="coolgray"
          @click.stop="cancelTimeEntry"
        >
          Cancel
        </v-btn>

        <v-btn
          color="primary"
          @click.stop="submitTimeEntry"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="['create', 'update'].includes(action)"
      class="pa-4"
    >
      <v-card-title>
        <div>
          <div>{{ action === "create" ? "Add" : "Edit" }} Time</div>

          <div
            v-if="action === 'create' && weekdate"
            class="coolgray--text overline"
          >
            <span class="font-weight-bold">
              {{ timeEntryDateText }}
            </span>
          </div>

          <div
            v-if="action === 'update'"
            class="coolgray--text overline"
          >
            Time Entry ID
            <span class="font-weight-bold">
              {{ entry.timeEntryId }}
            </span>
          </div>
        </div>

        <v-card-actions>
          <v-btn
            :ripple="false"
            icon
            @click.stop="close()"
          >
            <v-icon color="brightblue">
              close
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="timeEntryForm"
          v-model="validTimeEntryForm"
        >
          <v-container grid-list-xs>
            <v-row>
              <v-col>
                <v-menu
                  v-if="!weekdate"
                  ref="timeEntryDate"
                  v-model="timeEntryDateMenu"
                  :return-value="entry.time_entry_date"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="timeEntryDateText"
                      label="Entry Date"
                      readonly
                      :rules="timeEntryDateRules"
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="entry.time_entry_date"
                    no-title
                    scrollable
                    :allowed-dates="allowedDates"
                    :min="minDate"
                    :max="maxDate"
                    @input="$refs.timeEntryDate.save(entry.time_entry_date)"
                  />
                </v-menu>

                <v-autocomplete
                  v-if="showPersonDropdown"
                  v-model="entry.person_id"
                  autocomplete="off"
                  :items="delegates"
                  item-text="text"
                  item-value="personId"
                  label="Person"
                  clearable
                  :required="showPersonDropdown"
                  :rules="personRules"
                />

                <v-autocomplete
                  v-model="entry.client_id"
                  autocomplete="off"
                  :items="clients"
                  item-value="clientId"
                  label="Client"
                  clearable
                  required
                  :rules="orgRules"
                />

                <v-autocomplete
                  v-model="entry.project_id"
                  autocomplete="off"
                  :items="projects"
                  item-text="projectName"
                  item-value="projectId"
                  label="Projects"
                  clearable
                  :required="requiresProject(entry.time_classification_id)"
                  :rules="projectRules"
                  @input="validate"
                />

                <v-row wrap>
                  <v-col>
                    <v-text-field
                      ref="duration"
                      v-model="duration"
                      error-count="2"
                      hint="Hours:Minutes (HH:MM)"
                      name="duration"
                      type="textual"
                      label="Duration"
                      :required="true"
                      :rules="durationRules"
                      @input="validate"
                      @keydown="onKeyDown"
                    />
                  </v-col>

                  <v-col>
                    <v-select
                      v-model="entry.time_classification_id"
                      :items="timeTypes"
                      item-text="timeClassificationName"
                      item-value="timeClassificationId"
                      label="Time Type"
                      clearable
                      required
                      :rules="timeTypeRules"
                      @input="validate"
                    />
                  </v-col>
                </v-row>

                <v-textarea
                  v-model="entry.description"
                  class="border-light py-3"
                  counter="250"
                  label="Notes"
                  name="notes"
                  :required="requiresNotes(entry.time_classification_id)"
                  :rules="descriptionRules"
                  @input="validate"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card-actions class="justify-end">
                  <v-btn
                    text
                    color="coolgray"
                    @click.stop="cancelTimeEntry"
                  >
                    Cancel
                  </v-btn>

                  <v-btn
                    color="primary"
                    @click.stop="submitTimeEntry"
                  >
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import dates from 'src/utils/dates'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapGetters, mapState } = createNamespacedHelpers('internal')
const { mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'TimeModal',
  props: {
    timeShift: {
      type: Number,
      default: 0,
    },
    clients: {
      type: Array,
      default: () => [],
    },
    persons: {
      type: Array,
      default: () => [],
    },
    projects: {
      type: Array,
      default: () => [],
    },
    services: {
      type: Array,
      default: () => [],
    },
    timeTypes: {
      type: Array,
      default: () => [],
    },
    timeEntries: {
      type: Array,
      default: () => [],
    },
    week: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    loading: false,
    message: null,
    snackbar: false,
    status: null,
    timeEntryDateMenu: false,
    validTimeEntryForm: true,
  }),
  computed: {
    visible: {
      get() {
        return this.isTimeModalVisible
      },
      set(value) {
        this.$store.commit('internal/isTimeModalVisible', value)
      },
    },
    ...mapAuthGetters(['isInternalAdminUser']),
    ...mapGetters([
      'delegates',
      'maxCalendarDate',
      'minCalendarDate',
    ]),
    ...mapToolsState({
      reportName: (state) => state.reportName,
    }),
    ...mapState({
      action: (state) => state.action,
      entry: (state) => state.entry,
      isTimeModalVisible: (state) => state.isTimeModalVisible,
      selected: (state) => state.selected,
      weekdate: (state) => state.weekdate,
    }),
    minDate() {
      return dates.getFormattedDate(this.minCalendarDate, 'YYYY-MM-DD')
    },
    maxDate() {
      return dates.getFormattedDate(this.maxCalendarDate, 'YYYY-MM-DD')
    },
    timeTypeIdByName() {
      const { timeTypes } = this

      return timeTypes.reduce((original, type) => {
        const { timeClassificationId, timeClassificationName } = type

        original[timeClassificationName] = timeClassificationId

        return original
      }, {})
    },
    billableTimeTypeId() {
      const { timeTypeIdByName } = this
      return timeTypeIdByName.BILLABLE
    },
    projectTimeTypeId() {
      const { timeTypeIdByName } = this
      return timeTypeIdByName.PROJECT
    },
    viewTime() {
      const { timeEntries, didLoadTimeFail, isLoadingTime } = this

      return (
        isLoadingTime === false
        && didLoadTimeFail === false
        && timeEntries.length > 0
      )
    },
    successMessage() {
      const { action } = this

      if (action === 'create') return 'Added'
      if (action === 'update') return 'Edited'
      if (action === 'delete') return 'Deleted'

      return 'Success'
    },
    errorMessage() {
      const { action } = this

      if (action === 'create') return 'Failed to Add. Please try again.'
      if (action === 'update') return 'Failed to Edit. Please try again.'
      if (action === 'delete') return 'Failed to Delete. Please try again.'

      return 'Error'
    },
    timeEntryDateText() {
      const { entry: { time_entry_date } } = this
      return dates.getFormattedDate(time_entry_date, 'dddd, MMMM DD, YYYY')
    },

    showPersonDropdown() {
      const { delegates, isInternalAdminUser } = this

      return isInternalAdminUser || delegates.length > 1
    },
    descriptionRules() {
      const {
        entry: { time_classification_id: timeTypeId },
        billableTimeTypeId,
        projectTimeTypeId,
      } = this

      return [
        (value) => {
          const noteTimeTypes = [billableTimeTypeId, projectTimeTypeId]
          const requiresNotes = noteTimeTypes.includes(timeTypeId)
          const valid = !requiresNotes || (!!value && requiresNotes)

          return valid || 'Required for Billable & Project Time Types'
        },
        (value) => {
          const valid = !value || value.length <= 250

          return valid || 'Notes cannot exceed 250 characters.'
        },
      ]
    },
    duration: {
      get() {
        return this.durationFormatted
      },
      set(newValue) {
        const newEntry = { ...this.entry, ...{ duration: newValue } }

        this.$store.commit('internal/entry', newEntry)
      },
    },
    durationType() {
      const {
        entry: { duration = 0 },
      } = this

      if (/\./.test(duration)) return 'decimal'

      if (/:/.test(duration)) return 'hhmm'

      if (Number.isInteger(Number(duration))) return 'integer'

      return 'invalid'
    },
    durationArray() {
      const {
        durationType,
        entry: { duration = 0 },
      } = this
      const parsers = {
        decimal: this.durationDecimalAsArray,
        hhmm: this.durationTimeAsArray,
        integer: this.durationIntegerAsArray,
        invalid: () => [0, 0],
      }

      return parsers[durationType](duration)
    },
    durationFormatted() {
      const { durationArray } = this
      const [hh, mm] = durationArray
      const padHours = hh.toString().length < 2
      const padMinutes = mm.toString().length < 2

      const HH = padHours ? `0${hh}` : hh
      const MM = padMinutes ? `0${mm}` : mm

      return `${HH}:${MM}`
    },
    durationAsMinutes() {
      const { durationArray } = this
      const [HH, MM] = durationArray
      const hoursAsMinutes = HH * 60

      return hoursAsMinutes + MM
    },
    durationRules() {
      const {
        isDurationSet,
        isDurationInteger,
        isDurationIncrementOf15,
      } = this

      return [isDurationSet, isDurationInteger, isDurationIncrementOf15]
    },
    orgRules() {
      return [(value) => !!value || 'Required']
    },
    personRules() {
      return [(value) => !!value || 'Required']
    },
    projectRules() {
      const {
        entry: { time_classification_id },
        projectTimeTypeId,
      } = this

      return [
        (value) => {
          const isProjectTimeType = time_classification_id === projectTimeTypeId
          const valid = !isProjectTimeType || (!!value && isProjectTimeType)

          return valid || 'Required'
        },
      ]
    },
    timeTypeRules() {
      return [(value) => !!value || 'Required']
    },
    timeEntryDateRules() {
      const {
        entry: { time_entry_date },
        maxDate,
        maxCalendarDate,
        minDate,
        minCalendarDate,
      } = this

      return [
        (value) => !!value || 'Required',
        (/* value */) => {
          const valid = dates.areDatesInRange(
            time_entry_date,
            minCalendarDate,
            maxCalendarDate,
          )
          const validDateRange = `${minDate} - ${maxDate}`

          return valid || `Valid Date Range: ${validDateRange}`
        },
      ]
    },
  },
  methods: {
    validate() {
      return this.$refs.timeEntryForm.validate()
    },
    reset() {
      return this.$refs.timeEntryForm.reset()
    },
    resetValidation() {
      return this.$refs.timeEntryForm.resetValidation()
    },

    close() {
      this.resetValidation()
      this.visible = false
    },
    closeSnackbar() {
      this.snackbar = false
      this.status = null
      this.message = null
    },

    requiresNotes(time_classification_id) {
      const { billableTimeTypeId, projectTimeTypeId } = this
      const noteTimeTypes = [billableTimeTypeId, projectTimeTypeId]

      return noteTimeTypes.includes(time_classification_id)
    },
    requiresProject(time_classification_id) {
      const { projectTimeTypeId } = this

      return time_classification_id === projectTimeTypeId
    },

    async submitTimeEntry() {
      const { action, durationAsMinutes, entry, validTimeEntryForm } = this
      const { commit, dispatch } = this.$store

      const requiresValidation = ['create', 'update'].includes(action)
      const validates = () => requiresValidation && validTimeEntryForm
      const valid = !requiresValidation || validates()

      const timeEntry = { ...entry, ...{ duration: durationAsMinutes } }

      if (!valid) return false

      const response = await dispatch('internal/submitTimeEntry', timeEntry)

      const { content = [] } = response || {}

      if (content.code) {
        const message = content.message || 'Unknown API Error'

        commit('tools/snackbar', true)
        commit('tools/snack', { message, status: 'error' })

        return response
      }

      const [entryId] = content
      const status = entryId ? 'success' : 'error'
      const message = entryId ? this.successMessage : this.errorMessage

      if (status === 'success') {
        commit('internal/isTimeModalVisible', false)
      }

      commit('tools/snackbar', true)
      commit('tools/snack', { message, status })

      return response
    },
    cancelTimeEntry() {
      const { selected } = this

      this.$store.commit('internal/action', 'read')
      this.$store.commit('internal/entry', Object.create(selected))

      this.close()
    },

    // @TODO: [PLAN-421] Abstract duration methods to the internal store.
    decrementHour(hour, minutes) {
      const floor = hour <= 0

      if (floor) return '00'

      const result = minutes >= 0 && minutes < 15 ? hour - 1 : hour
      const pad = result.toString().length < 2

      return pad ? `0${result}` : result
    },
    incrementHour(hour, minutes) {
      const result = minutes >= 45 ? hour + 1 : hour
      const pad = result.toString().length < 2

      return pad ? `0${result}` : result
    },
    decrement15Minutes(minutes) {
      if (minutes > 15 && minutes <= 30) return '15'
      if (minutes > 30 && minutes <= 45) return '30'
      if (minutes > 45 && minutes <= 59) return '45'

      return '00'
    },
    increment15Minutes(minutes) {
      if (minutes >= 0 && minutes < 15) return '15'
      if (minutes >= 15 && minutes < 30) return '30'
      if (minutes >= 30 && minutes < 45) return '45'

      return '00'
    },

    incrementDuration() {
      const { durationArray, durationType } = this
      const [HH, MM] = durationArray

      const incrementHour = MM >= 45

      const newHour = this.incrementHour(HH, MM)
      const newMinutes = incrementHour ? '00' : this.increment15Minutes(MM)

      if (durationType === 'decimal') {
        const min = newMinutes ? newMinutes / 60 : 0
        const isDecimal = min && /./.test(min)
        const [, decimal] = isDecimal ? min.toFixed(2).split('.') : min

        return `${newHour}.${decimal}`
      }

      return `${newHour}:${newMinutes}`
    },
    decrementDuration() {
      const { durationArray, durationType } = this
      const [HH, MM] = durationArray

      const decrementHour = MM <= 0

      const newHour = this.decrementHour(HH, MM)
      const newMinutes = decrementHour ? '45' : this.decrement15Minutes(MM)

      if (durationType === 'decimal') {
        const nMinutes = Number(newMinutes)
        const minutes = nMinutes ? nMinutes / 60 : 0
        const isDecimal = minutes && /./.test(minutes)
        const decimal = isDecimal ? minutes.toFixed(2).split('.')[1] : '00'

        return `${newHour}.${decimal}`
      }

      return `${newHour}:${newMinutes}`
    },
    onKeyDown(event) {
      const { keyCode } = event
      const keyCodes = {
        arrowUp: 38,
        arrowDown: 40,
      }

      if (keyCode === keyCodes.arrowUp) {
        const newDuration = this.incrementDuration()
        this.$refs.duration.$emit('input', newDuration)
      } else if (keyCode === keyCodes.arrowDown) {
        const newDuration = this.decrementDuration()
        this.$refs.duration.$emit('input', newDuration)
      }
    },

    isDurationSet() {
      const { durationArray } = this
      const [HH, MM] = durationArray

      return (!!HH || !!MM) || 'Duration Required'
    },
    isDurationInteger() {
      const { durationArray } = this
      const [HH, MM] = durationArray
      const valid = Number.isInteger(HH) && Number.isInteger(MM)

      return valid || 'HH:MM Format Requires Numbers, e.g. 00:15'
    },
    isDurationIncrementOf15() {
      const { durationArray } = this
      const [, MM] = durationArray

      return MM % 15 === 0 || '15-Minute Increments Required'
    },

    durationDecimalAsArray(duration) {
      const nDuration = Number(duration)
      const hours = Math.floor(nDuration)
      const minutes = Math.round((nDuration - hours) * 60)

      return [hours, minutes]
    },
    durationIntegerAsArray(duration) {
      const nDuration = Number(duration)
      const hours = Math.floor(nDuration / 60)
      const hoursAsMinutes = hours * 60
      const minutes = Math.round(nDuration - hoursAsMinutes)

      return [hours, minutes]
    },
    durationTimeAsArray(duration) {
      const [hh, mm] = duration.split(':')
      const hours = Number(hh)
      const minutes = Number(mm)

      return [hours, minutes]
    },

    allowedDates(time_entry_date) {
      const { maxCalendarDate, minCalendarDate } = this

      return dates.areDatesInRange(
        time_entry_date,
        minCalendarDate,
        maxCalendarDate,
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

::v-deep.v-card {
  border-radius: 6px;
  border-width: 0;
}

::v-deep.v-card__title {
  color: $color-monochrome-darkestblue;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.51px;
  line-height: 22px;
  justify-content: space-between;
}

::v-deep.v-card__text {
  padding: 0;
}

.v-card + .v-card {
  margin-top: 1ch;
}
</style>
